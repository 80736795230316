<template>
  <!-- style="height: 351px; max-height: 351px;" -->
  <v-card-text class="pa-6" v-if="modulo_estoque">
    <template v-if="!eventoProdutos.length && !loadingProdutos">
      <div
        class="pa-6 fill-height d-flex flex-column align-center justify-center"
      >
        <div class="mb-6 headline text-center">Nenhum material encontrado!</div>
        <v-btn
          depressed
          color="toolbar"
          large
          dark
          @click="modalAddProduto = true"
        >
          Material
          <v-icon right>add</v-icon>
        </v-btn>
      </div>
    </template>

    <v-card outlined v-if="eventoProdutos.length && !loadingProdutos">
      <div
        class="mb-6 d-flex align-center justify-end"
        v-if="
          original.status != 3 && original.status != 4 && original.status != -1
        "
      >
        <v-btn depressed color="toolbar" dark @click="modalAddProduto = true">
          <v-icon class="mr-2">add</v-icon>
          Adicionar Material
        </v-btn>
      </div>

      <v-simple-table fixed-header>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Op</th>
              <th class="text-left">Produto</th>
              <th class="text-left">Deposito</th>
              <th style="width: 100px;" class="text-left">Quantidade</th>
              <th class="text-left">Serial</th>
              <th v-if="vueEventoMaterialCusto" class="text-left">SubTotal</th>
              <th style="width: 40px;" class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in eventoProdutos" :key="index">
              <td>
                <v-icon color="success" v-if="item.op === 'E'">
                  add_circle
                </v-icon>
                <v-icon color="error" v-if="item.op === 'S'">
                  remove_circle
                </v-icon>
              </td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <td v-on="on">
                    {{ excerpt(item.descricao, 12) }}
                  </td>
                </template>
                <span>{{ item.descricao }}</span>
              </v-tooltip>
              <td>{{ item.deposito_id }}</td>
              <td>{{ item.qtde }}</td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <td v-on="on">
                    {{ excerpt(item.serial, 12) }}
                  </td>
                </template>
                <span>{{ item.serial }}</span>
              </v-tooltip>

              <td v-if="vueEventoMaterialCusto">
                {{ (item.custo * item.qtde) | guarani }}
              </td>
              <td>
                <v-btn
                  icon
                  @click="delProduto(item.id)"
                  v-if="
                    original.status != 3 &&
                      original.status != 4 &&
                      original.status != -1
                  "
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr v-if="vueEventoMaterialCusto">
              <td>Total:</td>
              <td></td>
              <td></td>
              <td>{{ qtdeTotal() }}</td>
              <td></td>
              <td>{{ custoTotal() | guarani }}</td>
              <td></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <Carregando v-else />

    <v-dialog v-model="modalAddProduto" width="100%" max-width="400px">
      <v-card :loading="adicionandoProduto" :disabled="adicionandoProduto">
        <v-card-title class="lightgray subtitle-1">
          Adicionar Produto
          <v-spacer></v-spacer>
          <v-btn @click="modalAddProduto = false" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-6">
          <!-- Depositos -->
          <v-select
            v-model="selectedDeposito"
            :items="userDepositos"
            clearable
            item-text="deposito"
            item-value="deposito_id"
            filled
            dense
            hide-details
            label="Depositos"
            :loading="loadingDepositos"
            :disabled="loadingDepositos"
          ></v-select>

          <!-- Produtos -->
          <v-combobox
            v-if="produto.deposito_id && produtos.length"
            v-model="selectedProduto"
            :items="produtos"
            :loading="loadingProdutos"
            :disabled="loadingProdutos"
            class="mt-4"
            clearable
            item-text="produto"
            item-value="produto_id"
            dense
            filled
            hide-details
            label="Produto"
          ></v-combobox>

          <!-- Quantidade -->
          <v-text-field
            v-model.number="produto.qtde"
            v-if="selectedProduto && saldoObrigatorio === false"
            :disabled="selectedProduto.exige_serial"
            class="mt-4"
            label="Qtde"
            filled
            hide-details
            dense
          ></v-text-field>

          <!-- Serial -->
          <v-text-field
            v-model="produto.serial"
            v-if="
              selectedProduto &&
                selectedProduto.exige_serial &&
                saldoObrigatorio === false
            "
            class="mt-4"
            label="Serial"
            filled
            hide-details
            dense
          ></v-text-field>

          <v-card
            flat
            v-if="(original.op === 'A' && produto.deposito_id) || !original.op"
            class="mt-4"
          >
            <v-subheader
              style="height: auto !important"
              class="ma-0 pa-0 pb-1 body-1"
            >
              Operação
            </v-subheader>
            <v-btn-toggle
              style="width: calc(100% / 2);"
              dense
              v-model="produto.op"
              :class="$vuetify.breakpoint.mdAndDown ? 'btn-toggle-mobile' : ''"
            >
              <v-btn small height="36" block value="E">
                Entrada
              </v-btn>
              <v-btn small height="36" block value="S">
                Saida
              </v-btn>
            </v-btn-toggle>
          </v-card>

          <!-- Produto sem saldo -->
          <v-alert
            type="error"
            class="mt-4"
            v-if="selectedProduto && saldoObrigatorio === true"
          >
            Produto sem saldo!
          </v-alert>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            depressed
            small
            dark
            color="toolbar"
            :disabled="desabilitarAddProduto"
            @click="addProduto"
          >
            Adicionar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
  <v-card-text v-else>
    <NaoAutorizado />
  </v-card-text>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { getAllProdutos } from "@/services/api/produtos.api.js";
import {
  postEventosprodutos,
  deleteEventosprodutos,
  fetchProdutosDepositos,
} from "@/services/api/eventos-produtos.api.js";
import { fetchUserDepositos } from "@/services/api/usuarios.api.js";

export default {
  name: "EventoMateriais",
  props: ["eventoId"],
  data() {
    return {
      modalAddProduto: false,
      produto: {
        custo: "",
        deposito_id: "",
        evento_id: "",
        op: "",
        preco: "",
        produto_id: "",
        qtde: "",
      },
      produtos: [],
      userDepositos: [],
      selectedProduto: null,
      selectedDeposito: null,
      loadingProdutos: false,
      loadingDepositos: false,
      adicionandoProduto: false,
      loading: false,
    };
  },
  computed: {
    ...mapState("Tocsconfig", {
      modulo_estoque: (state) => state.modulo_estoque,
    }),
    ...mapGetters("Componentes", ["getAccess"]),
    vueEventoMaterialCusto() {
      return this.getAccess("vueEventoMaterialCusto", "visualizar");
    },
    ...mapState("Evento", {
      original: (state) => state.evento,
      eventoProdutos: (state) => state.eventosProdutos,
    }),
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
      user_id: (state) => state.usuario.id,
    }),
    evento_id() {
      return this.$route.params.eventoId;
    },
    desabilitarAddProduto() {
      let result = true;

      if (this.selectedProduto) {
        if (this.selectedProduto.exige_serial) {
          if (this.produto.serial && this.produto.op) {
            result = false;
          }
        } else {
          if (this.produto.qtde && this.produto.op) {
            result = false;
          }
        }
      }

      return result;
    },
    saldoObrigatorio() {
      let resposta = false;
      if (this.original.op === "S") {
        if (this.selectedProduto.saldo == 0) {
          resposta = true;
        } else {
          resposta = false;
        }
      } else if (this.original.op === "E") {
        resposta = false;
      } else {
        resposta = false;
      }
      return resposta;
    },
  },
  watch: {
    selectedDeposito(newDeposito, oldDeposito) {
      if (this.selectedDeposito) {
        this.produto.deposito_id = this.selectedDeposito;
        this.getProdutos(this.selectedDeposito);
      }
      if (newDeposito != oldDeposito) {
        Object.keys(this.produto).forEach((key) => {
          if (key != "deposito_id") {
            this.produto[key] = "";
          }
        });
        this.selectedProduto = null;
      }
    },
    selectedProduto() {
      if (!this.selectedProduto) return;
      if (this.selectedProduto.exige_serial) {
        this.produto.qtde = 1;
      } else if (this.produto.serial) {
        this.produto.serial = "";
      }
      this.produto.evento_id = this.evento_id;
      this.produto.produto_id = this.selectedProduto.produto_id;
      this.produto.custo = this.selectedProduto.costo;
      this.produto.preco = this.selectedProduto.precio;
      if (this.original.op != "A") {
        this.produto.op = this.original.op;
      }
    },
  },
  methods: {
    ...mapActions("Evento", {
      getEventosProdutos: "getEventosProdutos",
    }),
    excerpt(text, tamanho) {
      if (!text) return;
      if (text.length > tamanho) {
        return text.substr(0, tamanho) + "...";
      } else {
        return text;
      }
    },
    clearSearch() {},
    getProdutos(id) {
      this.loadingProdutos = true;
      fetchProdutosDepositos(id)
        .then((response) => {
          this.produtos = response;
          this.loadingProdutos = false;
        })
        .catch((error) => {
          this.loadingProdutos = false;
          console.log(error);
        });
    },
    addProduto() {
      this.adicionandoProduto = true;
      this.$Progress.start();
      postEventosprodutos(this.produto)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success("Produto adicionado com sucesso!");
            this.$Progress.finish();
            this.modalAddProduto = false;
            this.getEventosProdutos(this.evento_id);
            Object.keys(this.produto).forEach((key) => {
              this.produto[key] = "";
            });
            this.selectedProduto = null;
            this.selectedDeposito = null;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        })
        .finally(() => {
          this.adicionandoProduto = false;
        });
    },
    delProduto(id) {
      this.$Progress.start();
      deleteEventosprodutos(id)
        .then(() => {
          this.$toast.success("Produto deletado com sucesso!");
          this.$Progress.finish();
          this.getEventosProdutos(this.evento_id);
        })
        .catch((error) => {
          console.log(error);
          this.$Progress.fail();
        });
    },
    qtdeTotal() {
      if (!this.eventoProdutos.length) return;
      let total = 0;
      this.eventoProdutos.forEach((item) => {
        total += Number(item.qtde);
      });
      return total;
    },
    custoTotal() {
      if (!this.eventoProdutos.length) return;
      let total = 0;
      this.eventoProdutos.forEach((item) => {
        total += Number(item.custo * item.qtde);
      });
      return total;
    },
    getUserDepositos() {
      this.loadingDepositos = true;
      fetchUserDepositos(`?user_id=${this.user_id}`)
        .then((response) => {
          this.userDepositos = response;
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loadingDepositos = false));
    },
  },
  mounted() {
    if (this.modulo_estoque) {
      this.getEventosProdutos(this.evento_id);
      this.getUserDepositos();
    } else {
      this.$toast.error("Acesso negado!");
    }
  },
};
</script>

<style lang="scss" scoped></style>
